import React from 'react';
import Document from './Document';

const Link = React.memo(({ docuList, selectDownload, selectedDownload, FctSelectFile }) => {
  return (
    <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }}>
      {docuList.map((element, index) => (
        <div key={'File' + index} style={{ marginBottom: 60 }}>
          <Document
            documentId={index}
            name={element.name}
            onPressAction={() => selectDownload(index)} 
            setViewFile={() => FctSelectFile(index)}
            docuLink={element.url}
            isSelected={selectedDownload.includes(index)}
          />
        </div>
      ))}
    </div>
  );
});

export default Link;
