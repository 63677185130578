import React from "react"

function App({desc_row1, desc_row2, img }) {
    return (
        <div className="videoWrapper">
            <div style={{ padding:10}}>
                    <img src={img} style={{width:"100%", cursor: "pointer"}} />
                <div className="SubTextVideoName">
                    <span>{desc_row1}</span>
                    <span>{desc_row2}</span>
                </div>
            </div>
        </div>
    );
}

export default App;
