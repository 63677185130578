import './App.css';
import Logo from "./assets/logo.png";
import React, { useState } from "react";
import Link from './Components/Link';
import docuList from './docuList';
import VideoMinia from "./Components/Video";
import VideoPlayer from "./Components/VideoPlayer";
import axios from "axios";
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import teaser from "./assets/Video/Teaser - Symposium Sartellite 9-16 v3.mp4";
import miniaTeaser from "./assets/play.png"
const API = "https://api.freseniusvienna2024.com/Endpoint"
// const API = "http://192.168.0.19:8150"


var Key = undefined;
var currentTab = "Download";

if (typeof window !== 'undefined') {
  const CookieKey = Cookies.get('Key_PROD');
  if (CookieKey) {
    Key = CookieKey;
    currentTab = Cookies.get("Tab_PROD")
    axios.post(API + "/LogOn", { key: Key, tab: currentTab })
    .then((r) => {
    })
    .catch((e) => {
    })
  }
  else {
    axios.get(API + "/getId")
    .then((r) => {
      if (r.status === 200) {
        Key = r.data.key;
        Cookies.set('Key_PROD', r.data.key, { expires: 15, });
        Cookies.set('Tab_PROD', "Download", { expires: 15 });
      }
    })
    .catch((e) => {
    })
  }
}

function App() {
  const [email, setEmail] = useState("")
  const [selectedDownload, setSelectedDownload] = useState([])
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  
  const notify = (type, message) => {
    switch (type) {
      case 'succes':
        toast.success(message)
        break;
      case 'warn':
        toast.warn(message)
        break;
      case 'error':
        toast.error(message)
        break;
  
      default:
        toast.info(message)
        break;
    }
  };

  const confirmBlock = () => {
    return (
      <div className='BlockConfirm'>
        <div className='Confirm'>
          <div className='ConfirmText'>
            To send you our brochure of your choice we
            collect your email address and will store it for 30
            days. Your email address will be stored in secured
            datacenter in France and will be deleted after 30
            days.
          </div>
          <div className='ConfirmText'>
            To know your privacy rights and how we process
            personal data please read Fresenius Kabi Data
            Protection Statement for Healthcare
            Professionals here <a rel='noreferrer' target='_blank' href='https://www.fresenius-kabi.com/data-protection'>Data Protection Statement for
              Healthcare Professionals - Fresenius Kabi
              Biosimilars (fresenius-kabi.com)</a>
          </div>
          <div className='ConfirmText'>
            Clicking on SUBMIT, you agree with this Data
            Protection Statement and with processing your
            email address. You also agree to receive the
            brochure of your choice.
          </div>
          <input value={email} onChange={(v) => { setEmail(v.currentTarget.value) }} type='email' id='email' className='ConfirmText' style={{ boxSizing: "border-box", width: "100%", padding: 8, color: "#026BB4", border: "solid 4px #026BB4", borderRadius: 5, marginTop: 30 }} placeholder='ENTER EMAIL' />
          <div className='Button' onClick={() => sendDownloadList()}>
            SUBMIT
          </div>
        </div>
      </div>
    )
  }

  const sendDownloadList = () => {
    const header = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    if (email.length > 0) {
      if (email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
        if (selectedDownload.length > 0) {
          const downloadList = [];
          selectedDownload.forEach((v) => {
            const file = docuList[v];
            downloadList.push(file.name);
          })
          axios.post(API + "/download", { email: email, downloadList: downloadList, key: Key }, { header: header })
            .then((r) => {
              if (r.status === 200) {
                notify('success', "You will receive your brochures on your email address in a few minutes.")
                // alert("You will receive your brochures on your email address in a few minutes.")
              }
            })
            .catch((e) => {
              notify('error', "An error occured when you sent your email, please try again.")
              // alert("An error occured when you sent your email, please try again.")
            })
          // console.log("ok", email, " : ", downloadList)
        }
        else notify('warn', "Select at least one file to download." )
        // alert("Select at least one file to download.");
      }
      else {
        notify('warn', "This email is not valid, check your email.")
        // alert("This email is not valid, check your email.")
      }
    }
    else {
      notify('warn', "Your email address is necessary to get the brochure of your choice.")
      // alert("Your email address is necessary to get the brochure of your choice.")
    }
  }

  const selectDownload = (v) => {
    setSelectedDownload((prevSelectedDownload) => {
      if (prevSelectedDownload.includes(v)) {
        return prevSelectedDownload.filter((item) => item !== v);
      } else {
        return [...prevSelectedDownload, v];
      }
    });
    console.log("Download", selectedDownload, email)
  }

  const FctSelectFile = (v) => {
    axios.post(API + "/ReadDocument", { key: Key, file: docuList[v].name })
      .then((r) => {
      })
      .catch((e) => {
      })
  }

  const onProgressVideo = (percent, timeView) => {
    axios.post(API + "/WatchVideo", { key: Key, video: teaser, viewTime: timeView, percent: percent })
      .then((r) => {
      })
      .catch((e) => {
      })
  }

  const Video = () => {
    return (
      <div style={{ width: "100%", height: 'fit-content', marginLeft: "auto", marginRight: "auto", marginBottom: "auto", display: 'flex', justifyContent: 'center' }} onClick={() => setIsVideoOpen(true)}>
        <div className='videoMainWrapper'>
          <VideoMinia name={"Teaser - Symposium Sartellite 9-16 v3"} desc_row1={"\tOur symposium teaser"} desc_row2={"By Laurent PEYRIN-BIROULET"} img={miniaTeaser} />
        </div>
      </div>
    )
  }

  // if (selectedFile >= 0) {
  //   return (
  //     <div>
  //       <FileReader url={docuList[selectedFile].url} setQuit={setSelectedFile} />
  //     </div>
  //   );
  // }
  // else {
  return (
    <div className="App">
      {isVideoOpen ?
        <VideoPlayer sendProgress={onProgressVideo} video={teaser} setIsVideoOpen={setIsVideoOpen} />
        :
        null
      }
      <div className='mainBlock'>
        <div className='Logo'>
          <img src={Logo} alt='Fresenius Kabi logo' style={{ width: "100%" }} />
        </div>
        <div className='ContentWrap'>
          <ToastContainer />
          {Video()}
          <div>
            {/* <Tab text={"Medical Brochures"} /> */}
            {/* <Tab text={"Expert Interviews"} currentTab={selectedTab} onPressSet={changeTab} /> */}
          </div>
          <div className='BlockColor contentBlock'>
            {/* <Instruction text={instructions[selectedTab]} /> */}
            {/* {
                selectedTab === "Medical Brochures" ? Link() : Video()
              } */}
            <Link docuList={docuList} selectDownload={selectDownload} selectedDownload={selectedDownload} FctSelectFile={FctSelectFile} />
            {confirmBlock()}
          </div>
        </div>
      </div>
    </div>
  );
}
// }

export default App;
