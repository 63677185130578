import React from "react"

// 

function App({ documentId, name, onPressAction, setViewFile, docuLink, isSelected}) {

    const onPress = () => {
        onPressAction(documentId)
    }

    return (
        <div style={{position:"relative"}}>
            <div style={{ display: "inline-block" }} className="Case" onClick={() => onPress()}>
                <div className={isSelected ? "CaseSelected" : ""}>

                </div>
            </div>
            <div style={{ display: "inline-block", height:43, verticalAlign:"top",position:"relative", width:"85%" }} >
                <a href={docuLink} target="_blank" rel="noreferrer" onClick={() => setViewFile(documentId)}>
                <div className="CenterText">
                    {name}
                </div>
                </a>
            </div>
        </div>
    );
}

export default App;
