import React, {useState} from "react"
import ReactPlayer from "react-player";

function App({ video, setIsVideoOpen, sendProgress }) {
    const [duration, setDuration] = useState(0);
    const onProgress = (e) => {
        sendProgress((e.played * 100), e.playedSeconds)
    }

    const FctSetDuration = (d) => {
        setDuration(d)
    }

    return (
        <div className="PlayerWrapper" onClick={(e) => {
            e.preventDefault();
            setIsVideoOpen(false)}}>
            <div style={{ position: "relative" }}>
                <div className="QuitPlayer" onClick={() => setIsVideoOpen(false)}>
                    X
                </div>
                <div className="VideoPlayer" onClick={(e) => e.stopPropagation()}>
                    <ReactPlayer width={'100%'} height={"auto"} url={video} controls={true} style={{ backgroundColor: 'black'}}
                    onError={(e) => console.log(e)}
                    onProgress={onProgress}
                    progressInterval={1000}
                    onDuration={FctSetDuration}
                    config={{
                        file: {
                            attributes: {
                                controlsList: "nodownload",
                                onContextMenu: e => e.preventDefault()
                            }
                        }
                    }} />
                </div>
            </div>
        </div>
    );
}

export default App;
