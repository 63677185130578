const documents = [
  {
    name: "00. AURIEL-PsO infographic",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/00. AURIEL-PsO infographic.pdf",
  },
  {
    name: "01. FYB202 infographic phase I",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/01. FYB202 infographic phase I.pdf",
  },
  {
    name: "02. FYB202 infographic phase III",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/02. FYB202 infographic phase III.pdf",
  },
  {
    name: "03. Idacio AI vs PFS infographic",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/03. Idacio AI vs PFS infographic.pdf",
  },
  {
    name: "04. TNF inhibitors infographic",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/04. TNF inhibitors infographic.pdf",
  },
  {
    name: "05. Adalimumab MOA card_5sept24",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/05. Adalimumab MOA card_5sept24.pdf",
  },
  {
    name: "06. Crohns Disease PoD",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/06. Crohns Disease PoD.pdf",
  },
  {
    name: "07. Ulcerative Colitis PoD",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/07. Ulcerative Colitis PoD.pdf",
  },
  {
    name: "08. ADAM AI vs PFS_Sabet et al. 2022",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/08. ADAM AI vs PFS_Sabet et al. 2022.pdf",
  },
  {
    name: "09. AURIEL PsO_Hercogová J et al. Br J Dermatol 2020 Feb 182(2) 316-326",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/09. AURIEL PsO_Hercogová J et al. Br J Dermatol 2020 Feb 182(2) 316-326.pdf",
  },
  {
    name: "10. Switching to biosimilars current perspectives in immune mediated inflammatory diseases",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/10. Switching to biosimilars current perspectives in immune mediated inflammatory diseases.pdf",
  },
  {
    name: "11. TNF in IBD_Peyrin-Biroulet_2021",
    url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/11. TNF in IBD_Peyrin-Biroulet_2021.pdf",
  },
];

export default documents;
